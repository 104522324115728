import React from "react";
import { Chart } from "react-google-charts";

const BarChart = ({ diffdata, options }) => {
    return (
        <Chart
            chartType="BarChart"
            width={window.screen.width>1024?"500px":"0px"}
            height="250px"
            diffdata={diffdata}
            options={options}
        />
    );
}

export default BarChart;
