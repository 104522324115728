import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { toasterControl } from "../../../piservices/commonService";

const DynamicToaster = () => {
  const { toasterState, toasterMessage, toasterVariant } = useSelector((state) => state.onboarding);
  return (
    <Snackbar
      open={toasterState}
      autoHideDuration={4000}
      onClose={() => toasterControl(false, "", toasterVariant)}
      anchorOrigin={{ vertical: "top", horizontal: "right" }} // Position of the alert
    >
      <Alert severity={toasterVariant} variant="filled">
        {toasterMessage}
      </Alert>
    </Snackbar>
  );
};

export default DynamicToaster;
