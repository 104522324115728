import React from 'react'
import { formateDate } from '../../piservices/compCommon'

function ArchDetailsCard({data, showSourceLeads, setShowArchitectureDetails}) {
  return (

    <div style={{borderRadius:12, border:"1px solid #00000010", boxShadow: "0px 5px 12px 0px rgba(0, 0, 0, 0.10)", marginBottom:42, padding:"0px 24px 24px 24px",}}>
       <table style={{ width:"100%"}} >
      <thead style={{height:60}}>
        <th>{data.name}</th>
        <th></th>
        <th></th>
        <th></th>
      </thead>
      <tbody>
        <tr style={{backgroundColor:"white",height:60}} >
          <td style={{}}>
            <div style={{ fontSize: 12, fontWeight: "500", color: "#8E8E8E" }}>LOCATION</div>
            <div style={{ fontSize: 16, fontWeight: "500", color: "#333333" }}>{data.location}</div>
          </td>
          <td>
            <div style={{ fontSize: 12, fontWeight: "500", color: "#8E8E8E" }}>CONTACT NO.</div>
            <div style={{ fontSize: 16, fontWeight: "500", color: "#333333" }}>{data.contactNumber}</div>
          </td>
          <td>
            <div style={{ fontSize: 12, fontWeight: "500", color: "#8E8E8E" }}>EMAIL</div>
            <div style={{ fontSize: 16, fontWeight: "500", color: "#333333" }}>{data.email}</div>
          </td>
          <td>
            <div style={{ fontSize: 12, fontWeight: "500", color: "#8E8E8E" }}>ACTIVE SINCE</div>
            <div style={{ fontSize: 16, fontWeight: "500", color: "#333333" }}>{formateDate(data.activeSince)}</div>
          </td>
        </tr>
        <tr style={{backgroundColor:"white",height:60}}>
          <td>
            <div style={{ fontSize: 12, fontWeight: "500", color: "#8E8E8E" }}>BUSINESS CLOCKED</div>
            <div style={{ fontSize: 16, fontWeight: "500", color: "#333333" }}>{data.totalBusiness.toLocaleString('en-IN')}</div>
          </td>
          <td onClick={()=>{
            showSourceLeads(data.email)
            setShowArchitectureDetails(false)}}>
            <div style={{ fontSize: 12, fontWeight: "500", color: "#8E8E8E", cursor:"pointer" }}>LEADS GIVEN</div>
            <div style={{ fontSize: 16, fontWeight: "500", color: "#0084EF", cursor:"pointer"}}>{data.totalLeads}</div>
          </td>
          <td>
            <div style={{ fontSize: 12, fontWeight: "500", color: "#8E8E8E" }}>LAST CONTACT</div>
            <div style={{ fontSize: 16, fontWeight: "500", color: "#333333" }}>{formateDate(data.lastContactDate)}</div>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
   
  )
}

export default ArchDetailsCard