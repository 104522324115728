import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import not_found from '../../Assets/not_found.svg'
import Footer from '../Footer/Footer'
import "./index.css"
const NotFound = () => {
  const [startGame, setStartGame] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (window.location.href.includes("404")) {
        navigate("/find-professionals")
      }
    }, [10000])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <section className="vh-100 gradient-form bg-white container_section" >
        <div className='imageStyle'><img src ={not_found}/></div>
        <div className='contentStyle'>
          <div className='upperText'>Oops! Page Not Found</div>
          <div className='lowerText'>It seems like the link you followed is broken.</div>
          <Link to="/" ><div className='buttomStyle'><span className='buttonText'>Return to Home Page</span></div></Link>
        </div>
      </section>
      {/* <Footer /> */}
    </>
  )
}

export default NotFound